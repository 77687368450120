import { PrimaryButton, Seo, Text } from 'components';
import React, { useState } from 'react';
import styled from 'styled-components';
import { tablet } from 'styles/breakpoints';
import SMS from '../../assets/icons/sms.svg';
import Location from '../../assets/icons/location.svg';
import Home from '../../assets/icons/home.svg';
import { config } from 'config';
import { CircularProgress } from '@material-ui/core';
import Header from 'components/reviews/Header';
import Footer from 'components/reviews/Footer';
import LCFooter from 'components/LCFooter';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    honeypot: '',
  });
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const updateInput = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = event => {
    event.preventDefault();

    if (formData.honeypot === '') {
      sendEmail();
    } else {
      setError(true);
    }
  };

  const sendEmail = async () => {
    try {
      setLoading(true);
      await fetch(config.FIREBASE_MAILING, {
        method: 'POST',
        body: JSON.stringify({
          email: formData.email,
          name: formData.name,
          message: formData.message,
          funnelCode: 'EF',
        }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
      setFormData({
        name: '',
        email: '',
        message: '',
        honeypot: '',
      });
      setError(false);
      setSuccess(true);
    } catch {
      setSuccess(false);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Seo
        title="Contact Us"
        description="Find all the answers you need about no.Diet. Contact us by email or create a support ticket."
        name="Contact Us"
      />
      <Header isMobileApp={false} />
      <ContactSection>
        <LeftSide>
          <SectionTitle>Our contact details</SectionTitle>
          <Items>
            <ItemsLeft>
              <ItemWrap>
                <SmsLogo />
                <TextWrap>
                  <Title>Email address</Title>
                  <LeftSubTitle>hello@carnimeat.com</LeftSubTitle>
                </TextWrap>
              </ItemWrap>
              <ItemWrap>
                <HomeLogo />
                <TextWrap>
                  <Title>Company</Title>
                  <LeftSubTitle>Medical Score UAB</LeftSubTitle>
                </TextWrap>
              </ItemWrap>
              <ItemWrap>
                <HomeLogo />
                <TextWrap>
                  <Title>Company code</Title>
                  <LeftSubTitle>305445861</LeftSubTitle>
                </TextWrap>
              </ItemWrap>
            </ItemsLeft>
            <ItemsRight>
              <ItemWrap>
                <LocationLogo />
                <TextWrap>
                  <Title>Address of registration</Title>
                  <LeftSubTitle>
                    J. Zemgulio str. 46, Panevezys, Lithuania
                  </LeftSubTitle>
                </TextWrap>
              </ItemWrap>
              <ItemWrap>
                <LocationLogo />
                <TextWrap>
                  <Title>Address</Title>
                  <LeftSubTitle>
                    Antakalnio str. 17, Vilnius, Lithuania
                  </LeftSubTitle>
                </TextWrap>
              </ItemWrap>
            </ItemsRight>
          </Items>
        </LeftSide>
        <RightSide>
          <FormTitle>Contact customer support</FormTitle>
          <form onSubmit={handleSubmit}>
            <StyledLabel>
              Your email <StyledStar>*</StyledStar>
            </StyledLabel>
            <HoneyPot
              name="honeyPot"
              onChange={updateInput}
              value={formData.honeyPot || ''}
            />
            <StyledInput
              type="email"
              name="email"
              placeholder="Please enter your email"
              required={true}
              onChange={updateInput}
              value={formData.email || ''}
            />

            <StyledLabel>
              Subject <StyledStar>*</StyledStar>
            </StyledLabel>
            <StyledInput
              type="text"
              name="name"
              placeholder="Please enter a subject"
              required={true}
              onChange={updateInput}
              value={formData.name || ''}
            />

            <StyledLabel>
              Description <StyledStar>*</StyledStar>
            </StyledLabel>
            <StyledTextArea
              name="message"
              onChange={updateInput}
              placeholder="Your message"
              value={formData.message || ''}
            />

            <Note>
              Please enter the details of your request. A member of our team
              will respond as soon as possible.
            </Note>
            {error && (
              <ErrorText>There was an issue sending your email.</ErrorText>
            )}
            {success && (
              <SuccessText>Message was sent successfully!</SuccessText>
            )}
            <StyledButton disabled={loading} type="submit">
              {loading ? (
                <CircularProgress style={{ color: '#fff' }} size={22} />
              ) : (
                'Send'
              )}
            </StyledButton>
          </form>
        </RightSide>
      </ContactSection>
      <LCFooter disclaimerText={['© 2024 Carnimeat. All rights reserved.']} />
    </>
  );
};

export default Contact;

const ContactSection = styled.div`
  display: flex;
  padding: 3.5rem 3rem;
  justify-content: space-between;
  max-width: 90rem;
  width: 100%;
  margin: 0 auto;
  min-height: calc(100vh - 226px);
  @media ${tablet} {
    flex-direction: column-reverse;
    padding: 2.5rem 1rem;
    gap: 2rem;
  }
`;

const FormTitle = styled.h2`
  color: #000;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(119, 119, 119, 0.1);

  @media ${tablet} {
    font-size: 1.25rem;
  }
`;

const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Items = styled.section`
  display: flex;
  flex-wrap: wrap;
`;

const ItemsLeft = styled.section`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 14.6rem;
`;

const ItemsRight = styled(ItemsLeft)`
  width: 100%;
  max-width: 22.625rem;
`;

const SectionTitle = styled(Text)`
  color: #000;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  padding-bottom: 2.5rem;
  @media ${tablet} {
    font-size: 1.5rem;
    line-height: 120%;
    padding-bottom: 2rem;
  }
`;

const Title = styled(Text)`
  color: #000;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  @media ${tablet} {
  }
`;

const HomeLogo = styled(Home)`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.75rem;
`;

const LocationLogo = styled(Location)`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.75rem;
`;

const SmsLogo = styled(SMS)`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.75rem;
`;

const ItemWrap = styled.div`
  display: flex;
`;

const TextWrap = styled.div``;

const LeftSubTitle = styled(Text)`
  color: #777;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  padding-top: 0.25rem;
`;

const RightSide = styled.div`
  max-width: 38.75rem;
  width: 100%;
  padding: 1.5rem;
  border-radius: 0.5rem;
  background: #f5f5f5;
  form {
    padding-top: 1rem;
  }
  height: fit-content;
  @media ${tablet} {
    max-width: unset;
    padding: 1rem;
  }
`;

const HoneyPot = styled.input`
  display: none;
`;

const StyledStar = styled.span`
  color: #e53535;
`;

const SuccessText = styled(Text)`
  padding-bottom: 12px;
  font-size: 0.9rem;
  text-align: center;
  margin-bottom: 12px;
  color: rgb(6, 194, 112) !important;
`;

const ErrorText = styled(Text)`
  padding-bottom: 12px;
  font-size: 0.9rem;
  text-align: center;
  color: rgb(255, 59, 59) !important;
`;

const Note = styled.div`
  color: #777;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin-bottom: 1rem;
`;

const StyledButton = styled(PrimaryButton)``;

const StyledLabel = styled.label`
  color: #000;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
`;

const StyledInput = styled.input`
  color: #000;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  border-radius: 0.625rem;
  border: 0.994px solid #f5f5f5;
  background: #fff;
  height: 3rem;
  margin: 0.25rem 0 1rem;
  outline: none;
  padding: 0.87644rem 17.11881rem 0.87356rem 0.81869rem;
  width: 100%;
  -webkit-appearance: none;

  &::placeholder {
    color: #777;
  }
  @media ${tablet} {
  }
`;

const StyledTextArea = styled.textarea`
  color: #000;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  border-radius: 0.625rem;
  border: 0.994px solid #f5f5f5;
  background: #fff;
  height: 10.25rem;
  margin: 0.25rem 0 0.75rem;
  outline: none;
  padding: 0.875rem 0.8125rem;
  width: 100%;
  -webkit-appearance: none;

  &::placeholder {
    color: #777;
  }
  @media ${tablet} {
    height: 9rem;
  }
`;
